import React from 'react';
import { Row, Col } from 'antd';
import AboutTile from '../../AbouTile';
import { stripTags, domHtml } from '../../../utils/stripTags';

import SEO from '../../Seo';

const pageText = {
  paraOne: `I am a Full Stack Software Engineer who loves to code and explore.
   I am experienced working in various research and development projects. I have basic knowledge in many stacks and I quickly adapt to new things whether it's a new blockchain platform, 
   a programming language whatever it maybe if it's interesting,
    You can see me making it up and running within a short period of time.`,
  paraTwo: `  I am from a rapidly paced startup background and have played different roles in my company from development,
   project management, project scoping, consulting, architecting custom solutions for different problems using latest technology. 
   I also have taken part in many events as a speaker and trainer and have conducted workshops to increase awareness of upcoming developers on the blockchain.`,
};

const AboutMe = () => {
  const description = `${pageText.paraOne} ${stripTags(pageText.paraTwo)}`;
  return (
    <>
      <div>
        <SEO
          title="About"
          description={description}
          path=""
          keywords={['Sachu', 'Shaji', 'Abraham', 'Software Engineer', 'blockchain']}
        />
        <h1 className="titleSeparate">About Me</h1>
        <p>
          {pageText.paraOne}
        </p>
        <p dangerouslySetInnerHTML={domHtml(pageText.paraTwo)} />
      </div>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="location.png"
            height={60}
            alt="location image"
            textH4="Born and bought up in"
            textH3="Kottayam, KL, India"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="coffee.png"
            alt="coffee image"
            textH4="Love Coffee"
            textH3="Coffee + Me = Happiness"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="open-source.png"
            alt="open source"
            textH4="Open Source Contributer"
            textH3="To various projects"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="presenter.png"
            alt="Preseneter"
            textH4="Speaker at"
            textH3="Various Tech Events"
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="web.png"
            alt="web image"
            textH4="Full Stack Engineer"
            textH3="Loves to learn new things"
            height={60}
            width={60}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AboutTile
            img="graduation.png"
            alt="graduation image"
            textH4="Pursued B.Tech in"
            textH3="Computer Science"
            height={60}
            width={60}
          />
        </Col>
      </Row>
    </>
  );
};
export default AboutMe;
